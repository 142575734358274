<template>
  <aside class="ScrollToTop">
    <client-only>
      <transition
        name="BackToTop--Transition"
        appear
      >
        <button
          v-if="isScrollDisplayed"
          class="BackToTop"
          data-ux="Explore_BackToTop"
          data-test-id="Explore_BackToTop"
          @click="scrollTop()"
        >
          <span class="BackToTop--Icon">
            <HaIcon :icon="faArrowUp" />
          </span>
          <span class="visually-hidden">
            {{ $t('organization.backToTopLabel') }}
          </span>
        </button>
      </transition>
      <AdminSettings v-if="isAdmin" />
    </client-only>
  </aside>
</template>

<script setup lang="ts">
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons'
import {
  ref,
  computed,
  useRoute,
  onMounted,
  onUnmounted,
  useI18n
} from '#imports'
import { HaIcon } from '@ha/components-v3'
import { useStore } from 'vuex'
import AdminSettings from '@/components/AdminSettings/AdminSettings.vue'

const props = defineProps({
  beforeScroll: {
    type: Function,
    default: null
  }
})

const i18n = useI18n()

const isScrollDisplayed = ref(false)

const store = useStore()
const route = useRoute()

const isAdmin = computed(() => {
  return store.getters['user/isAdmin'](route.params.organization)
})

const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

const scroll = () => {
  props.beforeScroll?.()

  const scroll = window.scrollY
  const windowHeight = window.innerHeight

  if (scroll > windowHeight && isScrollDisplayed.value === false) {
    isScrollDisplayed.value = true
  }

  if (scroll < windowHeight && isScrollDisplayed.value === true) {
    isScrollDisplayed.value = false
  }
}

onMounted(() => {
  window.addEventListener('scroll', scroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', scroll)
})
</script>

<style lang="scss">
.ScrollToTop {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  pointer-events: none;

  @include mediaQuery(900) {
    width: auto;
    margin: calc(var(--gutter) / 2);
  }

  > * {
    pointer-events: initial;

    @include mediaQuery(900) {
      margin: calc(var(--gutter) / 2);
    }
  }
}

.BackToTop {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--gutter);
  margin-bottom: var(--gutter);
  padding: $ha-spacing-medium;
  color: var(--ha-color-text);
  font-size: $ha-font-size-large;
  background-color: var(--ha-color-white);
  border-radius: $ha-radius-large * 2; // 12px
  box-shadow: $ha-box-shadow-middle;

  @include transition-base((color, background-color, box-shadow));

  @include mediaQuery(900) {
    margin-right: calc(var(--gutter) / 2);
    margin-bottom: calc(var(--gutter) / 2);
    padding: $ha-unit * 2.5; // 20px
    font-size: $ha-font-size-large;
  }

  &:hover {
    color: var(--ha-color-white);
    background-color: set-lightness('text', 0.875);
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow:
      $ha-box-shadow-middle,
      0 0 0 ($ha-unit * 0.5) set-alpha('border', 0.5); // 0 0 0 4px
  }

  &:active {
    color: var(--ha-color-white);
    background-color: set-lightness('text', 0.75);
  }

  &--Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $ha-unit * 3; // 24px
    height: $ha-unit * 3; // 24px
  }

  &--Transition {
    &-enter,
    &-leave-to {
      opacity: 0;
    }

    &-enter-active,
    &-leave-active {
      @include transition-base((opacity));
    }
  }
}
</style>
